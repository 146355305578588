body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  overflow-x: auto;
  overflow-y: hidden;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-input-prefix {
  margin-right: 8px !important;
}
.ant-input-suffix {
  font-size: 18px !important;
}
.t-upload-picture-card div.ant-upload {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.t-upload-picture-card div.ant-upload .ant-upload-drag-icon {
  margin-bottom: 8px;
}
.t-upload-picture-card div.ant-upload .ant-upload-drag-icon svg {
  width: 0.8em;
  height: 0.8em;
}
.t-upload-picture-card div.ant-upload .ant-upload-text {
  font-size: 14px;
}
.t-upload-picture-card span.ant-upload {
  padding: 0 !important;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 22px !important;
}
.t-layout-setting-space.ant-space .ant-space-item-split {
  margin-bottom: 0 !important;
  width: 100%;
}
.t-layout-setting-space.ant-space .ant-space-item {
  margin-bottom: 0 !important;
}
.t-layout-setting-space.ant-space .ant-space-item .ant-list-header {
  padding-top: 0;
}
.globalSysStartUp .ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: #1890ff !important;
}
.globalSysStartUp .ant-spin .ant-spin-text {
  color: #1890ff !important;
}
#loginForm .ant-row .ant-input-affix-wrapper:focus,
#loginForm .ant-row .ant-input-affix-wrapper-focused,
#loginForm .ant-row .ant-input-affix-wrapper:hover {
  border-color: #1890ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}
#loginForm .ant-btn-primary {
  margin: 5px 0 5px 0;
  background: #1890ff !important;
  border-color: #1890ff !important;
}
.ruleRequired::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
:root {
  --primary-color: #1890ff;
}
