body {
  // min-width: 990px;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-x: auto;
  overflow-y: hidden;

  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// antd 全局样式覆盖
.ant-input-prefix {
  margin-right: 8px !important;
}

.ant-input-suffix {
  font-size: 18px !important;
}

// antd upload picture-card 样式重写
.t-upload-picture-card {
  div.ant-upload {
    display: inline-block;
    width  : 104px;
    height : 104px;
    margin : 0 8px 8px 0;

    .ant-upload-drag-icon {
      margin-bottom: 8px;

      svg {
        width : 0.8em;
        height: 0.8em;
      }
    }

    .ant-upload-text {
      font-size: 14px;
    }
  }

  span.ant-upload {
    padding: 0 !important;
  }
}

// 解决重置menu宽度后 引发的晃动问题
.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding-left: 22px !important;
}

//
.t-layout-setting-space.ant-space {
  .ant-space-item-split {
    margin-bottom: 0 !important;
    width        : 100%;


  }


  .ant-space-item {
    margin-bottom: 0 !important;

    .ant-list-header {
      padding-top: 0;
    }
  }
}



// 在加载页面强制蓝色（防止加载颜色主题闪烁问题）
.globalSysStartUp {
  .ant-spin {
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: #1890ff !important;
      }
    }

    .ant-spin-text {
      color: #1890ff !important;
    }
  }
}

// 在登录页面强制蓝色（防止加载颜色主题闪烁问题）
#loginForm {
  .ant-row {

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:hover {
      border-color: #1890ff !important;
      box-shadow  : 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    }
  }

  .ant-btn-primary {
    margin      : 5px 0 5px 0;
    background  : #1890ff !important;
    border-color: #1890ff !important;
  }
}

.ruleRequired::before {
  display     : inline-block;
  margin-right: 4px;
  color       : #ff4d4f;
  font-size   : 14px;
  font-family : SimSun, sans-serif;
  line-height : 1;
  content     : '*';
}